import React from 'react';
import MainRouter from './routers/main';

function App() {


  return (
      <MainRouter />
  );
}

export default App;
